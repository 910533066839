import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { MS_GRAPH_URL } from "../../services/Api";
import { getToken } from "../../constants/config/getToken";

const arrayBufferToBase64 = (buffer) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

// Async thunk to fetch user photo
export const getPhoto = createAsyncThunk(
  "user/getPhoto",
  async (_, thunkAPI) => {
    try {
      const token = await getToken();
      const response = await axios.get(MS_GRAPH_URL.GetUserPhoto, {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const photo = arrayBufferToBase64(response.data);
      return photo;
    } catch (error) {
      console.error("getPhoto API call failed", error);
      const errorMessage = error.response?.data || "An error occurred";
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const fetchOrganization = createAsyncThunk(
  "user/fetchOrganization",
  async (organizationId, thunkAPI) => {
    try {
      const token = await getToken();
      const response = await axios.get(
        MS_GRAPH_URL.GetOrganization(organizationId),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching organization", error);
      const errorMessage = error.response?.data || "An error occurred";
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

// Initial state
const initialState = {
  user: null,
  photo: "",
  isAuthenticated: false,
  jwtToken: "",
  organization: {},
  hasLoggedOut: false, // Add this flag
};

// Create slice
const homeSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
      state.hasLoggedOut = false;
    },
    setJwtToken: (state, action) => {
      state.jwtToken = action.payload;
    },
    logoutUser: (state) => {
      Object.assign(state, initialState);
      sessionStorage.clear();
      state.hasLoggedOut = true;
    },
    resetLogoutFlag: (state) => {
      state.hasLoggedOut = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPhoto.fulfilled, (state, action) => {
      state.photo = action.payload;
    });
    builder.addCase(getPhoto.rejected, (state, action) => {
      console.error("getPhoto rejected:", action.payload);
    });
    builder.addCase(fetchOrganization.fulfilled, (state, action) => {
      state.organization = action.payload;
    });
  },
});

export const { setUser, logoutUser, setJwtToken, resetLogoutFlag } =
  homeSlice.actions;

export default homeSlice.reducer;
