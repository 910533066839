import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  Suspense,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import { TableCell, IconButton, Link } from "@mui/material";
import Header from "../../components/headers/Header";
import SubHeader from "../../components/subHeader/SubHeader";
import InfoModal from "../../components/infoModal/InfoModal";
import ErrorModal from "../../components/error/errorModal";
import Instance from "./Instance";
import { fetchAllProjects } from "../../features/slices/projectSlice";
import { fetchAllInstances } from "../../features/slices/allInstances";
import { fetchKba } from "../../features/slices/kbaSlice";
import { useAuthentication } from "../../constants/config/useAuthentication";
import { checkAdminUsers } from "../../features/slices/UserData";
import language from "../../constants/languages/en/translations.json";
import { optionData } from "./ProjectData";
import { View } from "../../constants/icons/Icons";
import "./AllProjects.scss";

const ProjectsTable = React.lazy(() =>
  import("../../components/projectsTable/ProjectsTable")
);

const AllProjects = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const token = location.state?.token;
  const storedLanguage = localStorage.getItem("language") ?? "EN";
  const userDetails = useAuthentication();
  const instanceData = useSelector((state) => state.allInstances.data);
  const isInstanceApiError = useSelector(
    (state) => state.allInstances.isInstanceApiError
  );
  const isInstanceApiLoading = useSelector(
    (state) => state.allInstances.isInstanceApiLoading
  );
  const instanceApiErrorMessage = useSelector(
    (state) => state.allInstances.instanceApiErrorMessage
  );
  const allProjectsData = useSelector((state) => state.allProjects.data);
  const isLoading = useSelector((state) => state.allProjects.isLoading);
  const isError = useSelector((state) => state.isError);
  const errorMessage = useSelector((state) => state.errorMessage);

  const [selectedOption, setSelectedOption] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [infoModalData, setInfoModalData] = useState({
    title: "",
    description: "",
    kbaCode: "",
    languageCode: "",
  });

  const instanceBaseUrl =
    window?.env?.REACT_APP_INSTANCE_BASE_URL ||
    process.env.REACT_APP_INSTANCE_BASE_URL;
  const kbaID = "1";
  const version = 1;

  const [projectsByInstance, setProjectsByInstance] = useState([]);

  const instanceUrls = useMemo(
    () => ({
      DEV: process.env.REACT_APP_DEVLINK_BASE_URL,
      UAT: process.env.REACT_APP_UATLINK_BASE_URL,
    }),
    []
  );
  useEffect(() => {
    if (token) {
      dispatch(fetchAllInstances({ token }));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (instanceData?.data && token) {
      const instanceLinks = instanceData?.data.map(
        (instance) => instance.instanceLink
      );
      dispatch(fetchAllProjects({ token, instanceLinks }));
    }
  }, [dispatch, token, instanceData]);

  useEffect(() => {
    if (instanceData?.data && allProjectsData) {
      const combinedData = allProjectsData.map((project) => {
        const matchedInstance = instanceData?.data.find(
          (instance) => instance.instanceName
        );
        return {
          ...project,
          instanceName: matchedInstance?.instanceName || "Unknown Instance",
          instanceLink: instanceUrls[project.source] || instanceBaseUrl,
        };
      });
      setProjectsByInstance(combinedData);
    }
  }, [instanceData, allProjectsData, instanceBaseUrl, instanceUrls]);

  const filteredData = useMemo(() => {
    if (!searchQuery) return projectsByInstance;
    const query = searchQuery.toLowerCase();
    return projectsByInstance.filter(
      (item) =>
        item.name.toLowerCase().includes(query) ||
        item.clientName.toLowerCase().includes(query) ||
        item.instanceName.toLowerCase().includes(query)
    );
  }, [projectsByInstance, searchQuery]);

  const handleInfoClick = useCallback(
    async (language = storedLanguage) => {
      try {
        const langCode = language.toUpperCase();
        const response = await dispatch(
          fetchKba({ token, langCode, kbaID, version })
        ).unwrap();
        if (response.success) {
          const result = response.data;
          setInfoModalData({
            kbaCode: `KBA #${kbaID}`,
            title: result.name,
            languageCode: result.language,
            description: result.context,
          });
          setIsInfoModalOpen(true);
        }
      } catch (error) {
        console.error("Fetching KBA failed:", error);
        alert(
          "Fetching KBA failed: " + (error.message || "Unknown error occurred")
        );
      }
    },
    [dispatch, token, storedLanguage]
  );

  const handleCloseModal = () => setIsInfoModalOpen(false);
  const handleError = () => navigate("/");

  const handleSearch = useCallback((event) => {
    setSearchQuery(event);
  }, []);

  const columnsToDisplay = useMemo(
    () => [
      { colName: "name", label: t("Project"), showSorting: true },
      { colName: "clientName", label: t("Client"), showSorting: true },
      { colName: "instanceName", label: t("Instance"), showSorting: false },
      { colName: "Action", label: t("Action"), showSorting: false },
    ],
    [t]
  );

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const toggleModal = () => setIsOpen((prev) => !prev);

  const handleInstanceCancel = () => setIsOpen(false);

  const handleButtonClick = useCallback(
    (row) => {
      const projectId = row?.id;
      if (instanceBaseUrl && projectId && token) {
        document.cookie = `authToken=${token}; path=/; domain=${process.env.REACT_APP_DOMAIN}; secure; samesite=None;`;
        window.location.href = row.instanceLink.includes("dev")
          ? `${process.env.REACT_APP_DEVLINK_BASE_URL}project-home/${projectId}`
          : `${process.env.REACT_APP_UATLINK_BASE_URL}project-home/${projectId}`;
      }
    },
    [token, instanceBaseUrl]
  );

  const handleOnNext = useCallback(async () => {
    const email = userDetails?.userDetails?.username;
    if (!email) {
      console.error("User details are not available");
      return;
    }
    try {
      document.cookie = `authToken=${token}; path=/; secure; samesite=strict;`;
      const response = await dispatch(
        checkAdminUsers({ emailId: email, token })
      ).unwrap();
      if (response.data === true) {
        window.location.href = `${instanceBaseUrl}create-new-project`;
      } else {
        alert("You are not authorized to access this page.");
      }
    } catch (error) {
      console.error("Error checking admin access:", error);
    }
  }, [dispatch, userDetails, token, instanceBaseUrl]);

  const generateRowKey = (row) => row.id;

  const getCellStyle = (column) => {
    switch (column) {
      case "Action":
        return { textAlign: "right", width: "150px", paddingRight: "7%" };
      case "instanceName":
        return { textAlign: "center", width: "200px", paddingRight: "18%" };
      default:
        return { textAlign: "left", width: "250px", paddingLeft: "2%" };
    }
  };

  const renderTableCell = (column, value, row) => {
    const cellStyle = getCellStyle(column);
    if (column === "instanceName") {
      return (
        <TableCell key={column} style={cellStyle}>
          <Link href={row.instanceLink}>
            {row.instanceLink.includes("dev") ? "DEV" : "UAT"}
          </Link>
        </TableCell>
      );
    }
    if (column === "Action") {
      return (
        <TableCell key={column} style={cellStyle}>
          <IconButton onClick={() => handleButtonClick(row)}>
            <View />
          </IconButton>
        </TableCell>
      );
    }
    return (
      <TableCell key={column} style={cellStyle}>
        {value}
      </TableCell>
    );
  };

  if (isError || isInstanceApiError) {
    return (
      <ErrorModal
        setName={t("Error")}
        labelText={errorMessage || instanceApiErrorMessage}
        handleButtonClick={handleError}
        deleteButtonLabel={t("Reload")}
      />
    );
  }
  console.log("Token: ", token);
  return (
    <div style={{ height: "100vh" }}>
      <Header />
      <div className="all-projects-container">
        {(isLoading || isInstanceApiLoading) && (
          <div className="loader-overlay">
            <MotifProgressLoader show variant="default" />
          </div>
        )}
        {isOpen && (
          <Instance
            language={language}
            handleInstanceCancel={handleInstanceCancel}
            handleInfoClick={handleInfoClick}
            onNext={handleOnNext}
          />
        )}
        <div className="border-box">
          <SubHeader
            title={t("AllProjects")}
            selectedOption={selectedOption}
            addButtonText={language.NewProjectEN}
            onChangeSearchInput={handleSearch}
            onOptionChange={handleOptionChange}
            defaultSelection={language.DefaultEN}
            onClickNewProject={toggleModal}
            options={optionData}
            disableButton={isLoading || isInstanceApiLoading}
          />
          <Suspense fallback={<MotifProgressLoader show variant="default" />}>
            <ProjectsTable
              columns={columnsToDisplay}
              data={filteredData}
              itemsPerPage={5}
              generateRowKey={generateRowKey}
              getCellStyle={getCellStyle}
              renderTableCell={renderTableCell}
              handleButtonClick={handleButtonClick}
            />
          </Suspense>
        </div>
      </div>
      <InfoModal
        isOpen={isInfoModalOpen}
        onClose={handleCloseModal}
        data={infoModalData}
        handleInfoClick={handleInfoClick}
      />
    </div>
  );
};

export default AllProjects;
